<template>
  <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="customerSea-container">
    <div class="top-container">
      <div class="title-container">
        <div>线索公海</div>
        <div>
          <el-button class="common-screen-btn" type="primary" @click="addEdit()">新增线索</el-button>
          <el-button v-if="roles=='公司管理员'|| roles=='销售主管'||roles=='超级管理员'" :disabled="!this.multipleTable.length" class="common-screen-btn" type="primary" @click="() => {assignService()}">批量指派</el-button>
          <div class="common-upload-container" style="margin: 0">
            <el-button class="common-screen-btn" type="primary" :loading="btnLoading">批量导入</el-button>
            <input v-if="!btnLoading" class="common-upload-input" @input="(e) => {uploadXslx(e)}" type="file" />
          </div>
          <el-button class="common-screen-btn" type="text" @click="() => downloadTemplate()">模板下载</el-button>
          <el-button :disabled="!this.multipleTable.length" class="common-screen-btn" type="primary" @click="fishing()">批量捞取</el-button>
        </div>
      </div>
    </div>
    <div class="common-Summary-container">
      <div class="Summary-item">
        <div class="item-title">当前客户线索公海总数</div>
        <div class="item-num common-nofinger" style="color: #333">{{ topInfo.totalNumNow || 0 }}</div>
      </div>
      <div class="Summary-item Summary-item-line">
        <div class="item-title">累计客户线索公海总数</div>
        <div class="item-num common-nofinger" style="color: #333">{{ topInfo.totalNumAll || 0 }}</div>
      </div>
      <div class="Summary-item" style="align-items: center;">
        <el-select style="width: 80px;margin-left: 32px" v-model="topDate" @change="changeRadio">
          <el-option label="今日" :value="1"></el-option>
          <el-option label="昨日" :value="2"></el-option>
          <el-option label="本周" :value="3"></el-option>
          <el-option label="本月" :value="5"></el-option>
        </el-select>
      </div>
      <!-- <div class="Summary-item">
        <div class="item-title">客户总数</div>
        <div class="item-num common-nofinger">{{ totalNum || 0 }}</div>
      </div> -->
      <!-- <div class="Summary-item">今日</div> -->
      <div class="Summary-item">
        <div class="item-title">新增客户线索公海数</div>
        <!-- { name: '所属公司', type: 'select', modelKey: 'companyId',selOpt: this.rolesCompany} -->
        <div @click="
            () => {
              collect(
                'addCustomerNum',
                '新增客户线索公海数',
                [
                  { name: '客户名称', type: 'input', modelKey: 'name' },
                  { name: '打标状态', type: 'select', modelKey: 'markingStatus',selOpt: this.custDict['8']},
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '打标状态', colProp: ['markingStatusName'] },
                  { name: '创建人', colProp: ['createName'] }
                ]
              );
            }
          "
          class="item-num">{{ topInfo.addCustomerNum || "0" }}</div>
      </div>
      <!-- <div class="Summary-item">本月</div> -->
      <div class="Summary-item">
        <div class="item-title">新增线索私海打回客户数</div>
        <div @click="
            () => {
              collect(
                'repulseToSeaNum',
                '新增线索私海打回客户数',
                [
                  { name: '客户名称', type: 'input', modelKey: 'name' },
                  { name: '打标状态', type: 'select', modelKey: 'markingStatus',selOpt: this.custDict['8']},
                  { name: '创建人', type: 'input', modelKey: 'createName' },
                ],
                [
                  { name: '客户名称', colProp: ['name'] },
                  { name: '打标状态', colProp: ['markingStatusName'] },
                  { name: '创建人', colProp: ['createName'] }
                ]
              );
            }
          " class="item-num">{{ topInfo.repulseToSeaNum || "0" }}</div>
      </div>
    </div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>客户名称/手机号:</span>
        <el-input class="common-screen-input" placeholder="客户名称/手机号" v-model="params.name" clearable @clear="() => clearInput()"></el-input>
      </div>
      <div class="common-input-container">
        <span>打标状态:</span>
        <el-select
          class="common-screen-input"
          v-model="params.markingStatus"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['8']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>打标时间:</span>
        <el-date-picker class="common-screen-input" format="MM-dd" v-model="params.markingDate" @change="commonFun" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </div>
      <div class="common-input-container">
        <span>客户划分:</span>
        <el-select class="common-screen-input" v-model="params.projectType" @change="commonFun" placeholder="请选择" filterable clearable>
          <el-option label="项目" :value="217"> </el-option>
          <el-option label="散号" :value="218"> </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>客户级别:</span>
        <el-select
          class="common-screen-input"
          v-model="params.level"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['1']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>行业:</span>
        <el-select
          class="common-screen-input"
          v-model="params.industry"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['6']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>地区:</span>
        <a-cascader class="common-screen-input" :fieldNames="{label: 'name', value: 'id', children: 'children'}" v-model="params.areaId" :options="options" :load-data="loadData" placeholder="请选择" change-on-select @change="commonFun" />
      </div>
      <div class="common-input-container">
        <span>客户来源:</span>
        <el-select
          class="common-screen-input"
          v-model="params.source"
          @change="commonFun"
          placeholder="请选择"
          filterable
          clearable
        >
          <el-option
            v-for="item in custDict['2']"
            :key="item.id + ''"
            :label="item.name + ''"
            :value="item.id + ''"
          >
          </el-option>
        </el-select>
      </div>
      <!-- <div class="common-input-container">
        <span>创建时间:</span>
        <el-date-picker
          class="common-screen-date"
          v-model="params.createTime"
          @change="commonFun"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
        >
        </el-date-picker>
      </div> -->
      <div class="common-input-container">
        <common-date @commonGetDate="commonGetDate" :radio="commonRadio" :startDate="commonStartDate"  :endDate="commonEndDate" ref="commonReset"></common-date>
      </div>
      <span class="common-input-container">
        <el-button style="margin: 0" class="common-screen-btn" type="primary" @click="() => commonFun()"
          >查 询</el-button>
        <el-button class="common-screen-btn" plain @click="() => {resetBtn()}">重 置</el-button>
      </span>
      
    </div>
    <div class="gap"></div>
    <div class="common-padding">
      <el-table ref="multipleTable" class="common-table-haveborder common-table-bgGrey th" @selection-change="handleSelectionChange" :data="tableData" border>
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="客户名称" :formatter="tableColumn" >
      <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => getCustDeatil(scope.row)">{{scope.row.name}}</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="status_name"
        label="打标状态"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="project_type"
        label="客户划分"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="level_name"
        label="客户级别"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="source_name"
        label="客户来源"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="industry_name"
        label="行业"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="areaName"
        label="地区"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="note"
        label="备注"
      >
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.note" class="item" effect="dark" :content="scope.row.note" placement="bottom">
            <div class="common-overflow-drop">{{scope.row.note}}</div>
          </el-tooltip>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="markingTime"
        label="打标时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
        prop="create_name"
        label="创建人"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column
      width="160px"
        prop="create_time"
        label="创建时间"
        :formatter="tableColumn"
      ></el-table-column>
      <el-table-column label="操作" width="100" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="() => custFishing(scope.row)">捞取</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.pageNum"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>
    <el-dialog v-dialogDrag :title="'新增线索'" :append-to-body="true" :close-on-click-modal="false" :visible.sync="centerDialogVisible" width="520px">
      <div>
        <el-form :model="ruleFormAdd" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" >
          <el-form-item label="客户名称" prop="name">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.name"
              maxLength="30"
              placeholder="客户是指品牌方或联系人，请勿使用店铺名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户划分" prop="projectType">
            <el-select class="dialog-input" v-model="ruleFormAdd.projectType" placeholder="请选择" filterable >
              <el-option label="项目" :value="217" ></el-option>
              <el-option label="散号" :value="218" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-if="ruleFormAdd.projectType == 217" label="项目名称" prop="projectName">
            <el-input class="dialog-input" v-model="ruleFormAdd.projectName" maxLength="50" placeholder="请输入项目名称" ></el-input>
          </el-form-item>
          <el-form-item label="客户级别" prop="level">
            <el-select class="dialog-input" v-model="ruleFormAdd.level" placeholder="请选择" filterable >
              <el-option v-for="item in custDict['1']" :key="item.id + ''" :label="item.name + ''" :value="item.id + ''" ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.industry"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in custDict['6']"
                :key="item.id + ''"
                :label="item.name + ''"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="客户来源" prop="source">
            <el-select
              class="dialog-input"
              v-model="ruleFormAdd.source"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in custDict['2']"
                :key="item.id + ''"
                :label="item.name + ''"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-for="(item, index) in ruleFormAdd.phoneArr" :key="index" :prop="`phoneArr[${index}].value`" :rules="item.type == 'phone' ? rules.phoneArr : rules.wx">
            <template slot="label">
              <el-select v-model="item.type" style="width: 70%">
                <el-option label="手机" value="phone"></el-option>
                <el-option label="微信" value="wx"></el-option>
              </el-select>
            </template>
            <el-input class="dialog-input" v-model="item.value" maxLength="50" placeholder="请填写"></el-input>
            <span v-if="index == 0" class="el-icon-circle-plus-outline" @click="() => { addPhoneFun() }"></span>
            <span v-if="index != 0" class="el-icon-delete" @click="() => { deletePhoneFun(index) }"></span>
          </el-form-item>
          <el-form-item label="QQ" prop="qq">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.qq"
              maxLength="50"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="mail">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.mail"
              maxLength="50"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="网址" prop="web">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.web"
              maxLength="50"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="省市区" prop="address">
            <el-cascader :props="props" v-model="ruleFormAdd.areaId" class="dialog-input"></el-cascader>
          </el-form-item>
          <el-form-item label="详细地址" prop="address">
            <el-input
              class="dialog-input"
              v-model="ruleFormAdd.address"
              maxLength="50"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="note">
            <el-input
              type="textarea"
              class="dialog-input"
              v-model="ruleFormAdd.note"
              maxLength="500"
              placeholder="请填写"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="centerDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="() => submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      v-dialogDrag
      title="新建销售机会"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="520px"
    >
      <div>
        <el-form
          :model="chanceForm"
          :rules="chanceRules"
          ref="chanceForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-form-item label="新增销售机会" prop="name">
            <el-input
              class="dialog-input"
              v-model="chanceForm.name"
              maxLength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="客户名称" prop="custName">
            <el-input
              class="dialog-input"
              :value="chanceForm.custName"
              disabled
            ></el-input>
          </el-form-item>
           <el-form-item label="业务类型">
            <el-select
              class="dialog-input"
              v-model="chanceForm.type"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in custDict['12']"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="销售机会金额" prop="price">
            <el-input
              type="number"
              class="dialog-input"
              v-model="chanceForm.price"
            ></el-input>
          </el-form-item>
          <el-form-item label="销售阶段" prop="stage">
            <el-select
              class="dialog-input"
              v-model="chanceForm.stage"
              placeholder="请选择"
              filterable
            >
              <el-option
                v-for="item in custDict['4']"
                :key="item.id + ''"
                :label="item.name + ''"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="预计成单时间" prop="expectedTime">
            <el-date-picker
              class="dialog-input"
              v-model="chanceForm.expectedTime"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注" prop="remark">
            <el-input
              class="dialog-input"
              type="textarea"
              v-model="chanceForm.remark"
              maxLength="500"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="dialogVisible = false"
          >取 消</el-button
        >
        <el-button
          :loading="btnLoading"
          class="common-screen-btn"
          type="primary"
          @click="() => subChanceForm('chanceForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="指派客户负责人" :append-to-body="true" :visible.sync="assignVisible" width="520px">
      <div>
        <el-form :model="assignForm" :rules="assignRules" ref="assignForm" label-width="130px" class="demo-ruleForm">
          <el-form-item label="客户负责人" prop="chargeUserId">
            <el-select class="dialog-input" v-model="assignForm.chargeUserId" placeholder="请选择负责人" filterable>
              <el-option v-for="item in responsible" :key="item.id + ''" :label="item.nickName" :value="item.id + ''" ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="assignVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {batchPhoneUser('assignForm')}">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 捞取弹窗 -->
    <el-dialog v-dialogDrag :append-to-body="true" :visible.sync="laoDialogVisible" width="520px">
      <template slot="title"><div style="display:flex;align-items: center;"><img src="~@/assets/images/if-exclamation@1x.png" class="imgs"><span class="diatit">确认捞取</span> </div></template>
      <div class="diacon">确定捞取此客户吗？捞取客户后此客户将移动到线索私海列表内</div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="laoDialogVisible = false">取 消</el-button>
        <el-button class="common-screen-btn" type="primary" @click="() => {requestLao()}">确 定</el-button>
      </span>
    </el-dialog>
    <sea-detail
      ref="seaDetail"
      :custDict="custDict"
      :drawer="drawer"
      :tableRow="tableRow"
      :customerInfo="customerInfo"
      :adminInfo="adminInfo"
      :chanceFollowList="chanceFollowList"
      @handleClose="handleClose"
      @getCustDeatil="getCustDeatil"
      @loadFun="loadFun"
      @custFishing="custFishing"
      :loading="loading"
    ></sea-detail>

    <!-- 头部报表弹 -->
    <common-sum-dialog
      ref="commonSumDialog"
      :methodFuc="seaHead"
      :commonSumVisible="commonSumVisible"
      @handleClose="sumVisibleClose"
    ></common-sum-dialog>
  </div>
</template>
<script>
// import Breadcrumb from "../../components/common/breadcrumb.vue";
import SeaDetail from "./children/seaDetail.vue";
import { commonDict, sysArea, sysAreaDetail, getRoleByType } from "../../service/common.js";
import { custSeaList, seaDetail, addCustomer, custFishing, editCustomer, uploadSeaCus,seaHead,batchChargeUser } from "../../service/customer.js";
import { tableColumn, rulePrice, Config, DateTransform, getSectionDate, getLatelyDay } from "../../utils/index.js";
import CommonDate from "../../components/common/commonDate.vue"
import { Cascader } from 'ant-design-vue';
import { mapState } from "vuex"
import CommonSumDialog from "../../components/common/commonSumDialog"

export default {
  components: { 
    // Breadcrumb,
    SeaDetail, CommonDate, aCascader: Cascader, CommonSumDialog},
  data() {
    let ruleAccount = (rule, value, callback) => {
      let reg = /^[1][1,2,3,4,5,6,7,8,9][0-9]{9}$/;
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (!reg.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    let isEmaill = (rule, value, callback) => {
      let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("邮箱格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      seaHead,
      topDate: 1,
      multipleTable: [],
      assignForm: { // 批量指派电销负责人-form表单
        chargeUserId: '',
        customerIds: ''
      },
      assignRules: {
        chargeUserId: [{ required: true, message: "请选择负责人", trigger: "change" }],
      },
      assignVisible: false,
      responsible: [], // 负责人下拉
      options: [],
      props: {
        lazy: true,
        lazyLoad: async (node, resolve) => {
          const { level, value } = node;
          let typeId
          if (level == 0) {
            typeId = 0
          } else {
            typeId = value
          }
          let resData = (await sysArea({id: typeId})).data
          let nodes = resData.map((item) => ({
              value: item.id,
              label: item.name,
              leaf: level >= 2
            }))
          resolve(nodes)
        }
      },
      tableColumn,
      loading: false,
      centerDialogVisible:false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "营销", isLink: false },
        { title: "销售", isLink: false },
        { title: "线索公海", isLink: false },
      ],
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100],
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        headStartDate: DateTransform(new Date()),
        headEndDate: DateTransform(new Date()),
        companyId: this.$sto.get(Config.constants.userInfo).company_id,
        name: '',
        startTime: DateTransform(new Date()),
        endTime: DateTransform(new Date()),
      },
      customerInfo: "", // 客户详情
      adminInfo: "", // 系统详情
      chanceFollowList: "", // 跟进list
      custDict: { "1": [], "2": [], "4": [], "6": [], "8": [], "12": [] }, // 字典值
      tableRow: "", // 客户id用于编辑/查看
      dialogVisible: false,
      ruleFormAdd: {
        // 新增/修改
      },
      chanceForm: {
        // 机会form表单
      },
      rules: {
        name: [{ required: true, message: "请填写客户名称", trigger: "change" }],
        projectType: [{ required: true, message: "请选择客户划分", trigger: "change" }],
        projectName: [{ required: true, message: "请填写项目名称", trigger: "change" }],
        level: [{ required: true, message: "请选择客户等级", trigger: "change" }],
        industry: [{ required: true, message: "请选择所属行业", trigger: "change" }],
        source: [{ required: true, message: "请选择客户来源", trigger: "change" }],
        phoneArr: [{ validator: ruleAccount, trigger: "change" },{ required: true, message: "请填写手机号", trigger: "change" }],
        wx: [{ required: true, message: "请填写微信号", trigger: "change" }],
        mail: [{ validator: isEmaill, trigger: "change" }],
      },
      chanceRules: {
        name: [{ required: true, message: "请填写销售机会名称", trigger: "change" }],
        custName: [{ required: true, trigger: "change" }],
        price: [
          { required: true, message: "请填写销售机会金额", trigger: "change" },
          { validator: rulePrice, trigger: "change" },
        ],
        stage: [{ required: true, message: "请选择销售阶段", trigger: "change" }],
      },
      drawer: false, // 新增员工弹窗
      tableData: [],
      topInfo: {},
      //////////////////////////
      headerType: "",
      dialogTit: "",
      dialogVisible2: false,
      paramsTit: [],
      childColName: [],
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }),
      roles: this.$sto.get(Config.constants.userInfo).roles.join(','),
      companyId: this.$sto.get(Config.constants.userInfo).company_id,
      headerDialogParams: {},
      laoParams: {},
      laoDialogVisible: false,
      commonRadio: 1,
      commonStartDate: DateTransform(new Date),
      commonEndDate: DateTransform(new Date),
      // 汇总弹窗所需数据
      commonSumVisible: false,
    };
  },
  created() {
    if (this.rolesCompany && this.rolesCompany.length) {
      this.headerDialogParams.companyId = this.rolesCompany[0].id;
    }
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    if (this.$route.query.name && this.$route.query.id && this.$route.query.id != 'undefined') {
      this.params.name = this.$route.query.name
      this.getCustDeatil({ id: this.$route.query.id })
    }
    this.custSeaList();
    this.commonDict(1);
    this.commonDict(2);
    this.commonDict(4);
    this.commonDict(6);
    this.commonDict(8);
    this.commonDict(12);
    this.getRoleByType(0);
    this.sysArea(0)
  },
  computed: {
    ...mapState(["btnLoading"])
  },
  methods: {
    getWeekDate () { // 获取本周日期
        let now = new Date(); //当前日期
        let nowDayOfWeek = now.getDay() || 7; //今天本周的第几天
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        let weekStartDate = new Date(nowYear, nowMonth, nowDay - (nowDayOfWeek - 1));
        return [weekStartDate, new Date()]
    },
    changeRadio(e) {
        let date;
        this.activeType = 'D'
        switch (e) {
            case 1:
            date = [(new Date()), (new Date())];
            break;
            case 2:
            date = getLatelyDay(0);
            break;
            case 3:
            date = this.getWeekDate()
            break;
            case 5:
            date = getSectionDate('month')
            break;
        }
        this.params.headStartDate =  DateTransform(date[0])
        this.params.headEndDate =  DateTransform(date[1])
        this.custSeaList()
    },
    addPhoneFun() { // 添加一条目手机号
      this.ruleFormAdd.phoneArr.push({type: 'phone', value: ''})
    },
    deletePhoneFun(i) { // 删除一条目手机号
      this.ruleFormAdd.phoneArr = this.ruleFormAdd.phoneArr.filter((item, index) => {
        return index != i
      })
    },
    async getRoleByType(id) { // 负责人下拉
      let resData = (await getRoleByType({companyId: this.companyId,}, id)).data;
      this.responsible = resData;
    },
    async batchPhoneUser(formName) { // 批量指派电销负责人
      let assignForm = { ...this.assignForm };
      assignForm.companyId = this.params.companyId
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let res = await batchChargeUser(assignForm);
          if (res.code == 200) {
            this.$message.success("指派成功");
            this.custSeaList();
            this.assignVisible = false;
          }
          this.$refs.assignForm.resetFields()
        } else {
          return false;
        }
      });
    },
    assignService() {
      let multipleTable = [...this.multipleTable];
      if (!multipleTable || !multipleTable.length) {
        this.$message.error("请先选择");
        return false;
      }
      let newArr = [];
      multipleTable.map((item) => {
        newArr.push(item.id);
      });
      this.assignForm.customerIds = newArr.join(",")
      this.assignVisible = true;
    },
    handleSelectionChange(row) {
      console.log(row);
      this.multipleTable = row;
    },
    async sysArea (id) {
      let resData = (await sysArea({id: id})).data
      resData.map((item)=>{
        item.isLeaf = false
      })
      this.options = resData
    },
    async loadData(selectedOptions) {
      const targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true;
      let resData = (await sysArea({id: targetOption.id})).data
      resData.map((item)=>{
        if (item.level != 3) {
          item.isLeaf = false
        }
      })
    targetOption.children = resData;
      this.options = [...this.options];
      targetOption.loading = false;
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E6%89%B9%E9%87%8F%E6%A8%A1%E6%9D%BF/%E5%85%AC%E6%B5%B7%E5%AE%A2%E6%88%B7%E5%AF%BC%E5%85%A5(1).xlsx";
    },
    async uploadXslx(e) {
      // 上传表格
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (format != "xls" && format != "xlsx") {
        this.$message.error("仅支持xls、xlsx格式文件");
        e.target.value = "";
        return;
      }
      this.$vuex.commit("btnLoading", true);
      let res = await uploadSeaCus({ file: file });
      this.$vuex.commit("btnLoading", false);
      if (res.code == 200) {
        this.$message.success("上传成功")
      }
      // if (res.message) {
      //   this.$alert(res.message, "失败提示", {
      //     confirmButtonText: "确定",
      //   });
      // }
      e.target.value = "";
      this.custSeaList();
    },
    commonGetDate (startTime, endTime) {
      this.params.startTime = startTime
      this.params.endTime = endTime
      this.params.headStartDate = startTime
      this.params.headEndDate = endTime
      this.custSeaList()
    },
    clearInput () {
      // 筛选框清空-输入框
      this.custSeaList()
    },
    loadFun(value) {
      this.loading = value
    },
    commonFun() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.custSeaList();
    },
    companyFun() {
      this.headerDialogParams.companyId = this.params.companyId;
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.custSeaList();
    },
    custFishing(row) {
      // 捞取客户
      this.laoDialogVisible = true;
      this.laoParams.customerIds = row.id
    },
    subChanceForm(formName) {
      // 新增/编辑用户确认
      let chanceForm = { ...this.chanceForm };
      this.$vuex.commit("btnLoading", true);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (chanceForm.custName) {
            delete chanceForm.custName;
          }
          await custFishing(chanceForm);
          this.$message.success("捞取成功");
          this.custSeaList();
          this.dialogVisible = false;
          this.drawer = false;
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    submitForm(formName) {
      // 新增/编辑
      let ruleFormAdd = { ...this.ruleFormAdd };
      
      this.$vuex.commit("btnLoading", true);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (ruleFormAdd.areaId && ruleFormAdd.areaId.length) {
            ruleFormAdd.areaId = ruleFormAdd.areaId.join(',')
          }
          if (ruleFormAdd.phoneArr && ruleFormAdd.phoneArr.length) {
            let phone = []
            let wx = []
            ruleFormAdd.phoneArr.map((item) => {
              if (item.type == 'phone') {
                phone.push(item.value)
              } else {
                wx.push(item.value)
              }
            })
            ruleFormAdd.phone = phone.join(',')
            ruleFormAdd.wx = wx.join(',')
            delete ruleFormAdd.phoneArr
          }
          if (this.tableRow) {
            let { code } = await editCustomer(ruleFormAdd, ruleFormAdd.id);
            if (code == 200) {
              this.$message.success("修改成功");
              this.centerDialogVisible = false;
            }
          } else {
            let { code } = await addCustomer(ruleFormAdd);
            if(code == 200) {
              this.$message.success("添加成功");
              this.centerDialogVisible = false;
            }
          }
          this.custSeaList();
          
          this.drawer = false;
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    async getCustDeatil(row) {
      // 获取客户详情
      if (row) {
        this.tableRow = row;
        this.loading = true
        let resData = (await seaDetail({customerId: row.id})).data;
        this.drawer = true;
        this.loading = false
        if (resData.customerDeatailVo && resData.customerDeatailVo.areaId) {
          resData.customerDeatailVo.areaId = resData.customerDeatailVo.areaId.toString().split(',')
          resData.customerDeatailVo.areaName = (await sysAreaDetail({ id: resData.customerDeatailVo.areaId[resData.customerDeatailVo.areaId.length - 1] })).data
          if (resData.customerDeatailVo.phone) {
            if (resData.customerDeatailVo.wx) {
              resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.phone + ',' + resData.customerDeatailVo.wx
            } else {
              resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.phone
            }
          } else {
            resData.customerDeatailVo.phoneWx = resData.customerDeatailVo.wx
          }
        }
        this.customerInfo = resData.customerDeatailVo || ""; // 客户信息
        this.adminInfo = resData.admin || ""; // 系统信息
        // if (resData.list && resData.list.length) {
        //   resData.list.map((item) => {
        //     item.value = JSON.parse(item.value)
        //   })
        // }
        // this.chanceFollowList = resData.list || []; // 跟进信息
        this.ruleFormAdd = {
          id: resData.customerDeatailVo.id || "",
          name: resData.customerDeatailVo.name || "",
          level: resData.customerDeatailVo.level || "",
          industry: resData.customerDeatailVo.industry || "",
          source: resData.customerDeatailVo.source || "",
          phone: resData.customerDeatailVo.phone || '',
          wx: resData.customerDeatailVo.wx || "",
          qq: resData.customerDeatailVo.qq || "",
          mail: resData.customerDeatailVo.mail || "",
          web: resData.customerDeatailVo.web || "",
          address: resData.customerDeatailVo.address || "",
          note: resData.customerDeatailVo.note || "",
        };
      }
      this.$refs.seaDetail.nextPlanLst(row.id)
      this.$refs.seaDetail.planTaskList(row.id)
      this.$refs.seaDetail.workLog(row.id)
      this.loading = false
    },
    resetBtn() {
      // 重置
      this.$refs.commonReset.resetFun(this.commonRadio, this.commonStartDate, this.commonEndDate)
      this.params = {
        pageNum: 1,
        pageSize: 10,
        headStartDate: DateTransform(new Date()),
        headEndDate: DateTransform(new Date()),
        companyId: this.rolesCompany[0].id,
        name: '',
        startTime: DateTransform(new Date()),
        endTime: DateTransform(new Date()),
      };
      this.topDate = 1
      this.platId = [];
      this.custSeaList();
    },
    async custSeaList() {
      // 客户公海列表
      this.loading = true;
      let params = { ...this.params };
      if (params.areaId && params.areaId.length) {
        params.areaId = params.areaId[params.areaId.length - 1]
      } else {
        delete params.areaId
      }
      if (!params.projectType) {
        delete params.projectType
      }
      if (params.markingDate) {
        params.startMarkingTime = DateTransform(params.markingDate[0])
        params.endMarkingTime = DateTransform(params.markingDate[1])
        delete params.markingDate
      } else {
        delete params.markingDate
      }
      if(!params.name){
        delete params.name
      }
      let resData = (await custSeaList(params)).data;
      this.pagination.total = resData.total;
      this.pagination.pageSize = resData.size;
      this.tableData = resData.records;
      this.topInfo = {
        totalNumNow: resData.totalNumNow,
        totalNumAll: resData.totalNumAll,
        addCustomerNum: resData.addCustomerNum,
        repulseToSeaNum: resData.repulseToSeaNum,
        addMarkingNum: resData.addMarkingNum
      }
      this.loading = false;
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.pageNum = e;
      }
      this.custSeaList();
    },
    async commonDict(type) {
      let resData = (await commonDict({}, type)).data;
      this.custDict[`${type}`] = resData;
    },
    addEdit() { // 新增客户
      this.tableRow = "";
      this.ruleFormAdd = {
        phoneArr: [{type: 'phone', value: ''}]
      };
      if (this.$refs["ruleForm"]) this.$refs["ruleForm"].resetFields();
      this.centerDialogVisible = true;
    },
    handleClose() {
      this.custSeaList();
      this.drawer = false;
      // 关闭新增员工弹窗
      // done();
    },
    /////////////////////////////////////////////////
    handleClose2() {
      this.dialogVisible2 = false
    },
    async requestLao(){
      let params = {...this.laoParams}
      params.companyId = this.params.companyId
      params.chargeUserId = this.$sto.get(Config.constants.userInfo).user_id
      const res = await batchChargeUser(params)
      if(res.code == 200){
        this.$message.success("捞取成功");
        this.custSeaList();
        this.laoDialogVisible = false
        this.drawer = false
      }
    },
    // 数据汇总弹窗start
    sumVisibleClose () {
      this.commonSumVisible = false
    },
    collect(type, name, screen, arr) {
      // 汇总弹窗-子组件参数 type：弹窗类型，name：弹窗标题，screen：筛选，arr：表格数据
      this.$refs.commonSumDialog.getAllData(
        type,
        name,
        screen,
        arr,
        this.params.companyId,
        '',
        '',
        '',
        [this.params.headStartDate,this.params.headEndDate],
      );
      this.commonSumVisible = true;
    },
    // 数据汇总弹窗end
    fishing(){ // 批量捞取
      console.log('批量捞取');
      let multipleTable = [...this.multipleTable];
      if (!multipleTable || !multipleTable.length) {
        this.$message.error("请先选择");
        return false;
      }
      let newArr = [];
      multipleTable.map((item) => {
        newArr.push(item.id);
      });
      this.laoParams.customerIds = newArr.join(",")
      this.laoDialogVisible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.customerSea-container {
  .common-screen-container {
    padding-left: 32px;
  }
  .top-container {
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    .title-container {
      height: 56px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      padding: 0 16px 16px 16px;
      align-items: center;
      position: relative;
      &::after {
        content: ' ';
        background: #e6e6e6;
        width: 120%;
        height: 1px;
        position: absolute;
        left: -10%;
        bottom: -1px;
        z-index: 999999;
      }
      div {
        font-size: 24px;
        color: #101010;
      }
    }
  }
  /deep/.el-button {
    white-space: pre-line;
    text-align: left;
    line-height: 24px;
  }
  text-align: left;
  .nameIcon {
    width: 32px;
    height: 32px;
    color: #f56a00;
    background-color: #fde3cf;
    border-radius: 50%;
    text-align: center;
    line-height: 32px;
  }
}
.el-icon-circle-plus-outline, .el-icon-delete {
  color: #1890ff;
  margin-left: 10px;
  cursor: pointer;
  font-size: 16px;
}
.dialog-input {
  width: 320px !important;
}
.time {
  margin-right: 16px;
}
.selbtn {
  margin-left: 0px;
}
.tit-img {
  margin-left: 3px;
  margin-top: 2px;
  cursor: pointer;
}
.gap{
  height: 16px;
  background-color: #ededed;
}
.imgs{
  width: 24px;
  height: 24px;
  background-color: #ffdbdc;
  border-radius: 50%;
  vertical-align: middle;
}
.diatit{
  margin-left: 12px;
  font-size: 20px;
  color: #333;
}
.diacon{
  font-size: 14px;
  color: #101010;
  margin-left: 10px;
}
</style>
